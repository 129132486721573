@import "variables";
@import "bootstrap/bootstrap";

.container-site {
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  max-width: 970px;
}

.top-nav {
  display: block;
  border-bottom: 1px solid #D0D0D0;
  overflow: hidden;
  font-size: 12px;
}

.top-logo {
  border-bottom: 1px solid #D0D0D0;
}

.content-box {
  border-bottom: 1px solid #D0D0D0;
  padding: 20px;
  width: 100%;

}

div, button, p, body, a, ul, ol, li {
  border-radius: 0 !important;
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 12pt;
}

p, li {
  text-align: left;
  line-height: 1.74;
  font-weight: 400;
  font-size: 21px;
}

a.text {
  text-align: left;
  line-height: 1.74;
  font-weight: 400;
  font-size: 21px;
}

div.footer {
  min-height: 30px;
}

a.nav-link {
  color: #000;
  font-size: 10pt;
}

a.nav-link.active {
  color: #4A90E2;
  font-size: 10pt;
}

a.deco-none {
  text-decoration: none;
}

.disclaimer {
  color: #666 !important;
  font-weight: 500;
  font-size: 10pt;
  text-decoration: none;
}

div.disclaimer {
  background-color: #f8f8f8;
}

.paragraph {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

#map {
  padding-top: 50%;
}

.black {
  color: #000000 !important;
}

li.nav-item {
  line-height: 1.0;
}
